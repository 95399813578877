<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <button @click="$router.go(-1)" class="btn" v-bind:class="darkModeBtn">Go Back</button>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { darkModeStyle } from '../../../assets/js/darkMode';

export default {
  name: 'AccountDetailsMain',
  data() {
    return {
      darkMode: darkModeStyle('bg-dark text-light', 'bg-light text-dark'),
      darkModeText: darkModeStyle('text-light', 'text-dark'),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
    }
  }
}
</script>